import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // 라우터를 import

const app = createApp(App);

// Vue Router를 애플리케이션에 추가
app.use(router);

app.mount('#app');
