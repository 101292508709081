<template>
  <div id="app">
    <!-- 라우터에 의해 관리되는 컴포넌트 표시 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 전역 스타일 추가 가능 */
</style>
