<template>
  <div class="bg-image secondary"></div>
  <div class="bg-image primary"></div>
  <div class="container">
    <img src="@/assets/logo.ja.png" alt="Logo" class="logo" />
    <div class="buttons">
      <a href="https://studentmaker.basakansoft.com/ja/privacy_policy.html" class="text-button">プライバシーポリシー</a>
      <a href="https://studentmaker.basakansoft.com/ja/terms.html" class="text-button">利用規約</a>
      <a href="https://caring-challenge-9d3.notion.site/2ba6364a9311475aa0e4c3bc2c79d03f?pvs=4" target="_blank" class="text-button">確率型アイテム情報</a>
    </div>
    <!-- 새로운 이미지 버튼 컨테이너 추가 -->
    <div class="image-buttons">
      <!-- Google Play 버튼 -->
      <a href="https://play.google.com/store/apps/details?id=com.basakansoft.mezasenankandai">
        <img src="@/assets/google_play_black.png" alt="Google Play" />
      </a>

      <!-- App Store 버튼 -->
      <a href="https://apps.apple.com/jp/app/目指せ難関大-戦略的高校生活シミュレーター/id6502697535">
        <img src="@/assets/app_store_black.png" alt="App Store" />
      </a>
    </div>
  </div>
  <div class="footer">
    <img src="@/assets/basakansoft.png" class="company-logo" />
  </div>
</template>

<script>
export default {
  name: "HomeJa",
  data() {
    return {
      wallpaperList: [
        require('@/assets/wallpaper/wallpaper204.png'),
        require('@/assets/wallpaper/wallpaper6.png'),
        require('@/assets/wallpaper/wallpaper1001.png'),
        require('@/assets/wallpaper/wallpaper183.png'),
      ],
      transitionDelay: 4000, // 1000ms 보다는 커야함 (opacity transition duration)
      currentIndex: 0,
    };
  },
  mounted() {
    this.initBackgroundSwitch();
    this.animateBounceInfinite();
  },
  methods: {
    initBackgroundSwitch() {
      const primaryDiv = document.querySelector('.primary');
      const secondaryDiv = document.querySelector('.secondary');
      // Function to switch between divs
      const switchBackground = () => {
        // Update index for the next wallpaper
        this.currentIndex = (this.currentIndex + 1) % this.wallpaperList.length;

        // Set the next wallpaper on the hidden div (secondary)
        secondaryDiv.style.backgroundImage = `url(${this.wallpaperList[this.currentIndex]})`;
        primaryDiv.style.opacity = '0';
        // After the transition, swap roles of the divs (toggle primary/secondary)
        setTimeout(() => {
          this.currentIndex = (this.currentIndex + 1) % this.wallpaperList.length;
          primaryDiv.style.backgroundImage = `url(${this.wallpaperList[this.currentIndex]})`;
          primaryDiv.style.opacity = '1';
        }, this.transitionDelay);
      };
      switchBackground();
      setInterval(switchBackground, this.transitionDelay * 2);
    },
    animateBounceInfinite() {
      const logo = document.querySelector('.logo');
      const animate = () => {
        logo.classList.add('animate-bounce');
        setTimeout(() => {
          logo.classList.remove('animate-bounce');
        }, 2500);
      };
      animate();
      setInterval(animate, 5000);
    },
  }
};
</script>

<style scoped>
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';

body, html {
  margin: 0;
  padding: 0;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(12px);
  isolation: isolate;
  transition: opacity 1s ease-in-out;
}

.bg-image::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
      to right,
      rgba(255, 250, 233, 0.3) 0%,
      rgba(255, 250, 233, 0.8) 20%,
      rgba(255, 250, 233, 0.9) 50%,
      rgba(255, 250, 233, 0.8) 80%,
      rgba(255, 250, 233, 0.3) 100%
  );
}

@media (max-width: 800px) {
  .bg-image::after {
    background: linear-gradient(
        to right,
        rgba(255, 250, 233, 0.65) 0%,
        rgba(255, 250, 233, 0.8) 25%,
        rgba(255, 250, 233, 0.9) 50%,
        rgba(255, 250, 233, 0.8) 75%,
        rgba(255, 250, 233, 0.65) 100%
    );
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 98vh;
  position: relative;
}

.logo {
  margin-bottom: 20px;
  width: 280px;
}

.animate-bounce {
  animation: bounce 2s;
}

.text-button {
  margin: 5px;
  color: #4D2600;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

a {
  font-family: 'Roboto', sans-serif;
}

.buttons {
  margin-bottom: 20px; /* 버튼들과 이미지 버튼 사이의 여백 추가 */
}

.image-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-buttons a img {
  margin: 5px;
  width: 150px; /* 이미지 크기 조절 */
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: gray;
  font-size: 11px; /* You can adjust this value to make the text bigger or smaller */
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.company-logo {
  width: 110px;
}
</style>
