<template>
  <div class="container">
    <div class="tab">
      <button
          class="tablinks"
          :class="{ active: selectedLocale === 'kr' }"
          @click="selectLocale('kr')"
      >
        KR
      </button>
      <button
          class="tablinks"
          :class="{ active: selectedLocale === 'jp' }"
          @click="selectLocale('jp')"
      >
        JP
      </button>
    </div>
    <div v-show="selectedLocale === 'kr'" class="tabcontent">
      <img src="@/assets/logo.png" alt="Logo" class="logo"/>
      <div class="buttons">
        <div style="text-align: center">
          <a href="https://student-maker.com/ko/privacy_policy.html" class="text-button">Privacy Policy (KO)</a>
          <a href="https://student-maker.com/ko/terms.html" class="text-button">Terms of Use (KO)</a>
        </div>
        <div style="text-align: center">
          <a href="https://www.notion.so/a6f162f2ad044761807e8437a963c847?pvs=4" class="text-button">Probability
            Information for Probabilistic Item (KO)</a>
        </div>
      </div>
    </div>
    <div v-show="selectedLocale === 'jp'" class="tabcontent">
      <img src="@/assets/logo.ja.png" alt="Logo" class="logo"/>
      <div class="buttons">
        <div style="text-align: center">
          <a href="https://studentmaker.basakansoft.com/ja/privacy_policy.html" class="text-button">Privacy Policy
            (JA)</a>
          <a href="https://studentmaker.basakansoft.com/ja/terms.html" class="text-button">Terms of Use (JA)</a>
        </div>
        <div style="text-align: center">
          <a href="https://caring-challenge-9d3.notion.site/2ba6364a9311475aa0e4c3bc2c79d03f?pvs=4" class="text-button">Probability
            Information for Probabilistic Item (JA)</a>
        </div>
      </div>
    </div>
    <div class="footer">
      Company name: Basakansoft | Address: 222 Wangsimni-ro, Seongdong-gu, Seoul, Unicorn Room, 1st floor, Comax Startup
      Town, Hanyang University <br> Business number: 306-09-10281 | Inquiry: official@basakansoft.com
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeGlobal",
  data() {
    return {
      selectedLocale: "kr", // default locale
    };
  },
  methods: {
    selectLocale(localeName) {
      this.selectedLocale = localeName;
    },
  },
};
</script>

<style scoped>

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #FF8B55;
  background-color: #FFF9EA;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 30px;
  transition: 0.3s;
  font-size: 15px;
  color: #FF8B55;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #FF8B55;
  color: #FFF9EA;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #FF8B55;
  color: #FFF9EA;
}

/* Style the tab content */
.tabcontent {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #FFFAE9;
  position: relative; /* This allows us to position the footer at the bottom */
}

.logo {
  margin-bottom: 20px;
  width: 300px;
}

.text-button {
  margin: 5px;
  color: #4D2600;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

a {
  font-family: 'Roboto', sans-serif;
}

.buttons {
  margin-bottom: 20px; /* 버튼들과 이미지 버튼 사이의 여백 추가 */
}

.image-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-buttons a img {
  margin: 5px;
  width: 150px; /* 이미지 크기 조절 */
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: gray;
  font-size: 14px; /* You can adjust this value to make the text bigger or smaller */
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

</style>
